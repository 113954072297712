"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CarouselHandler = void 0;
var CarouselHandler = /** @class */ (function () {
    function CarouselHandler(carousel) {
        this.carousel = carousel;
        this.swiper = this.carousel.swiper;
    }
    /**
     * @method init Swiperの初期化
     **/
    CarouselHandler.prototype.init = function () {
        var _a;
        if (!this.carousel.container)
            return;
        if (this.carousel.type === "with-thumbs") {
        }
        (_a = this.carousel.swiper) === null || _a === void 0 ? void 0 : _a.init();
    };
    /**
     * @method loopFirstView スライドが1以上の時にloop
     * @param {Swiper} swiper
     */
    CarouselHandler.prototype.loopFirstView = function (swiper) {
        var slides = swiper.slides.length;
        // if (slides > 1) return
        swiper.loopCreate();
    };
    return CarouselHandler;
}());
exports.CarouselHandler = CarouselHandler;
